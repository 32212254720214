// _reset.scss

#ssl-landing-wrapper {

    dl,
    dt,
    dd,
    ol,
    ul,
    li {
        margin: 0;
        padding: 0;
    }

    .clear {
        clear: both;
        display: block;
        font-size: 0px;
        height: 0px;
        line-height: 0;
        width: 100%;
        overflow: hidden;
    }

    ::selection {
        background: #1860aa;
        color: #FFF;
        text-shadow: none;
    }

    ::-moz-selection {
        background: #1860aa;
        /* Firefox */
        color: #FFF;
        text-shadow: none;
    }

    ::-webkit-selection {
        background: #1860aa;
        /* Safari */
        color: #FFF;
        text-shadow: none;
    }

    :active,
    :focus {
        outline: none !important;
    }


    /* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/


    body {
        line-height: 1.5;
        color: #555;
        font-family: 'Roboto', sans-serif;
    }

    a {
        text-decoration: none !important;
        color: #1860aa;
    }

    a:hover {
        // color: #222;
    }

    a img {
        border: none;
    }

    img {
        max-width: 100%;
    }

    iframe {
        border: none !important;
    }


    /* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/


    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #444;
        font-weight: 600;
        line-height: 1.5;
        margin: 0 0 30px 0;
        font-family: 'Roboto', sans-serif;
    }

    h5,
    h6 {
        margin-bottom: 20px;
    }

    h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 14px;
    }

    h6 {
        font-size: 12px;
    }

    h4 {
        font-weight: 600;
    }

    h5,
    h6 {
        font-weight: bold;
    }

    h1>span:not(.nocolor),
    h2>span:not(.nocolor),
    h3>span:not(.nocolor),
    h4>span:not(.nocolor),
    h5>span:not(.nocolor),
    h6>span:not(.nocolor) {
        color: #1860aa;
    }

    p,
    pre,
    ul,
    ol,
    dl,
    dd,
    blockquote,
    address,
    table,
    fieldset,
    form {
        margin-bottom: 30px;
    }

    small {
        font-family: 'Roboto', sans-serif;
    }

    .uppercase {
        text-transform: uppercase !important;
    }

    .tright {
        text-align: right !important;
    }

    .tleft {
        text-align: left !important;
    }

    .fright {
        float: right !important;
    }

    .fleft {
        float: left !important;
    }

    .fnone {
        float: none !important;
    }

    .ohidden {
        position: relative;
        overflow: hidden !important;
    }

    #wrapper {
        position: relative;
        float: none;
        width: 1220px;
        margin: 0 auto;
        background-color: #FFF;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .line,
    .double-line {
        clear: both;
        position: relative;
        width: 100%;
        margin: 60px 0;
        border-top: 1px solid #EEE;
    }

    .line.line-sm {
        margin: 30px 0;
    }

    span.middot {
        display: inline-block;
        margin: 0 5px;
    }

    .double-line {
        border-top: 3px double #E5E5E5;
    }

    .emptydiv {
        display: block !important;
        position: relative !important;
    }

    .allmargin {
        margin: 50px !important;
    }

    .leftmargin {
        margin-left: 50px !important;
    }

    .rightmargin {
        margin-right: 50px !important;
    }

    .topmargin {
        margin-top: 50px !important;
    }

    .bottommargin {
        margin-bottom: 50px !important;
    }

    .clear-bottommargin {
        margin-bottom: -50px !important;
    }

    .allmargin-sm {
        margin: 30px !important;
    }

    .leftmargin-sm {
        margin-left: 30px !important;
    }

    .rightmargin-sm {
        margin-right: 30px !important;
    }

    .topmargin-sm {
        margin-top: 30px !important;
    }

    .bottommargin-sm {
        margin-bottom: 30px !important;
    }

    .clear-bottommargin-sm {
        margin-bottom: -30px !important;
    }

    .allmargin-lg {
        margin: 80px !important;
    }

    .leftmargin-lg {
        margin-left: 80px !important;
    }

    .rightmargin-lg {
        margin-right: 80px !important;
    }

    .topmargin-lg {
        margin-top: 80px !important;
    }

    .bottommargin-lg {
        margin-bottom: 80px !important;
    }

    .clear-bottommargin-lg {
        margin-bottom: -80px !important;
    }

    .nomargin {
        margin: 0 !important;
    }

    .noleftmargin {
        margin-left: 0 !important;
    }

    .norightmargin {
        margin-right: 0 !important;
    }

    .notopmargin {
        margin-top: 0 !important;
    }

    .nobottommargin {
        margin-bottom: 0 !important;
    }

    .header-stick {
        margin-top: -50px !important;
    }

    .content-wrap .header-stick {
        margin-top: -80px !important;
    }

    .footer-stick {
        margin-bottom: -50px !important;
    }

    .content-wrap .footer-stick {
        margin-bottom: -80px !important;
    }

    .noborder {
        border: none !important;
    }

    .noleftborder {
        border-left: none !important;
    }

    .norightborder {
        border-right: none !important;
    }

    .notopborder {
        border-top: none !important;
    }

    .nobottomborder {
        border-bottom: none !important;
    }

    .noradius {
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        -ms-border-radius: 0 !important;
        -o-border-radius: 0 !important;
        border-radius: 0 !important;
    }

    .col-padding {
        padding: 60px;
    }

    .nopadding {
        padding: 0 !important;
    }

    .noleftpadding {
        padding-left: 0 !important;
    }

    .norightpadding {
        padding-right: 0 !important;
    }

    .notoppadding {
        padding-top: 0 !important;
    }

    .nobottompadding {
        padding-bottom: 0 !important;
    }

    .noabsolute {
        position: relative !important;
    }

    .noshadow {
        box-shadow: none !important;
    }

    .hidden {
        display: none !important;
    }

    .nothidden {
        display: block !important;
    }

    .inline-block {
        float: none !important;
        display: inline-block !important;
    }

    .center {
        text-align: center !important;
    }

    .divcenter {
        position: relative !important;
        float: none !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .bgcolor,
    .bgcolor #header-wrap {
        background-color: #1860aa !important;
    }

    .color {
        color: #1860aa !important;
    }

    .border-color {
        border-color: #1860aa !important;
    }

    .nobg {
        background: none !important;
    }

    .nobgcolor {
        background-color: transparent !important;
    }

    .bgicon {
        display: block;
        position: absolute;
        bottom: -60px;
        right: -50px;
        font-size: 210px;
        color: rgba(0, 0, 0, 0.1);
    }

    /* Reset Select */
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        outline: 0;
        box-shadow: none;
        border: 0 !important;
        background: #2c3e50;
        background-image: none;
    }

    /* Remove IE arrow */
    select::-ms-expand {
        display: none;
    }

    /* Custom Select */
    .select {
        position: relative;
        display: flex;
        width: 20em;
        height: 3em;
        line-height: 3;
        background: #2c3e50;
        overflow: hidden;
        border-radius: .25em;
    }

    select {
        flex: 1;
        padding: 0 .5em;
        color: #fff;
        cursor: pointer;
    }

    /* Arrow */
    .select::after {
        content: '\25BC';
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 1em;
        background: #34495e;
        cursor: pointer;
        pointer-events: none;
        -webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
    }

    /* Transition */
    .select:hover::after {
        color: #f39c12;
    }
}