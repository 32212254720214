// _mixins.scss

$break-small0: 0px;
$break-small: 960px;
$break-small-2: 768px;
$break-large: 1200px;
$lp: 1440px;
$lps: 1279px;
$container: 1170px;


$white: #ffffff;
$light-green: #cbe9d4;
$dark: #000000;
$grey3: #242424;
$green: #0e884f;
$dark: #3a3a3a;
$orange: #ff721e;
$grey2: #3c3c3c;
$grey4: #21233f;

$montserrat-b-83: 83px;
$montserrat-b-84: 84px;
$montserrat-l-20: 20px;
$montserrat-10: 10px;
$montserrat-22: 22px;
$montserrat-33: 33px;
$montserrat-b-23: 23px;
$montserrat-b-35: 35px;
$montserrat-m-58: 58px;

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,800,800i&display=swap');

@mixin moneb($size: 13px) {
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: $size;
}

@mixin monb($size: 13px) {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: $size;
}

@mixin monl($size) {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: $size;
}

@mixin monr($size: 13px) {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: $size;
}

@mixin ar-20-m() {
    font-family: "Arial";
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.2px;
}

@mixin ar-m() {
    font-family: "Arial";
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.2px;
}

@mixin ar-b() {
    font-family: "Arial";
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.2px;
}

@mixin ar-32-b() {
    font-family: "Arial";
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.2px;
}

@mixin ul($img: '../images/li-bg.png') {
    li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 25px;

        &::before {
            content: "";
            background-image: url($img);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            z-index: 0;
            padding: 0 25px 0 0;
            display: block;
            float: left;
            width: auto;
            height: 22px;
            margin-right: 15px;
        }
    }
}

@mixin vertical-menu-wrapper {
    margin: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
}

@mixin vertical-menu-item($margin-right: 32px, $margin-bottom: 22px, $color: $white) {
    display: inline-flex;
    margin-right: $margin-right;
    text-align: center;
    margin-bottom: $margin-bottom;

    a {
        color: $white;
        @include monr();
        letter-spacing: -0.05px;

        &:hover {
            cursor: pointer;
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

@mixin bgimg($url, $vpos: center, $size: contain, $height: 100%, $width: 100%) {
    // height: calc(#{$height});
    width: calc(#{$width});
    background-image: url($url);
    background-size: $size;
    background-repeat: no-repeat;
    background-position: $vpos center;
    z-index: 0;
}

@mixin vh100 {
    height: 100vh;
}

@mixin box-shadow($color: $dark) {
    -webkit-box-shadow: 0 5px 10px rgba(53, 61, 177, 0.3);
    -moz-box-shadow: 0 5px 10px rgba(53, 61, 177, 0.3);
    box-shadow: 0 5px 10px rgba(53, 61, 177, 0.3);
}

@mixin box-shadow2($color: $dark) {
    -webkit-box-shadow: 1px 1px 21px rgba(87, 56, 6, 0.19);
    -moz-box-shadow: 1px 1px 21px rgba(87, 56, 6, 0.19);
    box-shadow: 1px 1px 21px rgba(87, 56, 6, 0.19);
}

@mixin box-shadow3 ($color: $dark) {
    -webkit-box-shadow: 1px 1px 21px rgba(127, 126, 125, 0.33);
    -moz-box-shadow: 1px 1px 21px rgba(127, 126, 125, 0.33);
    box-shadow: 1px 1px 21px rgba(127, 126, 125, 0.33);
}


// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------
// Flexbox display
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
    -webkit-flex-flow: $flow;
    -moz-flex-flow: $flow;
    -ms-flex-flow: $flow;
    flex-flow: $flow;

    @media screen and (max-width: $lps) {
        display: block;
    }
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
    -webkit-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -ms-flex-grow: $grow;
    flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number> 
@mixin flex-shrink($shrink) {
    -webkit-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
    flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width> 
@mixin flex-basis($width) {
    -webkit-flex-basis: $width;
    -moz-flex-basis: $width;
    -ms-flex-basis: $width;
    flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around 
@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch 
@mixin align-content($align) {
    -webkit-align-content: $align;
    -moz-align-content: $align;
    -ms-align-content: $align;
    align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch 
@mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch 
@mixin align-self($align) {
    -webkit-align-self: $align;
    -moz-align-self: $align;
    -ms-align-self: $align;
    align-self: $align;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// translate
@mixin translate ($x,
    $y) {
    @include transform(translate($x,
            $y));
}

// skew
@mixin skew ($x,
    $y) {
    @include transform(skew(#{$x}deg,
            #{$y}deg));
}

//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
}

// calc
@mixin calc($property,
    $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transition-property($property...) {
    -moz-transition-property: $property;
    -o-transition-property: $property;
    -webkit-transition-property: $property;
    transition-property: $property;
}

@mixin transition-duration($duration...) {
    -moz-transition-property: $duration;
    -o-transition-property: $duration;
    -webkit-transition-property: $duration;
    transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function: $timing;
    -o-transition-timing-function: $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
    transition-delay: $delay;
}

@mixin light($size) {
    font-size: $size;
    font-weight: 300;
}

@mixin mobileNav {

    display: none;

    .hamburger-menu {
        display: flex;
        justify-content: start;
        align-items: center;
        .menu-title {
            color: $white;
            padding-left: 40px;
        }
    }
    @media screen and (max-width: $lps) {
        display: block !important;
    }

    #menu__toggle {
        opacity: 0;
    }

    #menu__toggle:checked~.menu__btn>span {
        transform: rotate(45deg);
    }

    #menu__toggle:checked~.menu__btn>span::before {
        top: 0;
        transform: rotate(0);
    }

    #menu__toggle:checked~.menu__btn>span::after {
        top: 0;
        transform: rotate(90deg);
    }

    #menu__toggle:checked~.menu__box {
        visibility: visible;
        left: 0;
    }

    .menu__btn {
        display: flex;
        align-items: center;
        position: absolute;
        left: 10px;

        width: 26px;
        height: 26px;

        cursor: pointer;
        z-index: 1;
    }

    .menu__btn>span,
    .menu__btn>span::before,
    .menu__btn>span::after {
        display: block;
        position: absolute;

        width: 100%;
        height: 2px;

        background-color: $white;

        transition-duration: .25s;
    }

    .menu__btn>span::before {
        content: '';
        top: -8px;
    }

    .menu__btn>span::after {
        content: '';
        top: 8px;
    }

    .menu__box {
        display: block;
        position: fixed;
        visibility: hidden;
        top: 0;
        left: -100%;

        width: 300px;
        height: 100%;

        margin: 0;
        padding: 60px 0 20px;

        list-style: none;

        background-color: $green;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);

        transition-duration: .25s;
        overflow: auto;
    }

    .menu__item {
        display: block;
        padding: 12px 24px;

        color: #333;

        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 600;

        text-decoration: none;

        transition-duration: .25s;

        a {
            color: $white;
            @include monr(16px);
            letter-spacing: -0.05px;
    
            &:hover {
                cursor: pointer;
            }
        }
    }

    .menu__item:hover {
        background-color: #CFD8DC;
    }
}