// @import "../node_modules/purecss/build/pure-min.css";
@import "reset";
@import "mixins";

$section-width-withou-one-part: calc(((100% - 1170px - 50px) / 2) + 1170px);

.container.single-page {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    background: #fff !important;

    >.row {
        margin-left: 0;
        margin-right: 0;

        .col .bg1,
        .col .bg2 {
            display: none;
        }

        .col {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

#ssl-landing-wrapper {
    line-height: 1.2;
    position: relative;
    font-family: 'Montserrat', sans-serif;

    .container {
        margin: 0 auto;
        max-width: $container;
        position: relative;

        @media screen and (max-width: $lps) {
            width: 95%;
        }
    }

    [class^="section-"] {
        position: relative;
    }

    * {
        box-sizing: border-box;
    }

    .flex-od-row {
        @include flexbox();
        @include align-items(center);

        @media screen and (max-width: $lps) {
            display: block !important;
        }
    }

    .mobile-navigation {
        @include mobileNav();
        padding: 20px 0;
    }

    [class^="col-od-md-"] {
        padding-left: 0;
        position: relative;

        margin-right: 20px;
        padding-right: 0;
        align-items: center;
        display: flex;

        &:last-child {
            margin-right: 0;
        }

        @include flex-direction(column);

        @media screen and (max-width: $lps) {
            // width: 270px;
            width: 100% !important;
            margin: 0 auto 40px !important;
            float: none !important;
            display: block !important;
        }

        .name {
            text-align: left;
            text-transform: uppercase;
            margin-top: 45px;
            margin-bottom: 30px;
            width: 100%;
            padding-left: 50px;
        }

        .label {
            background: $orange;
            color: $white;
            @include monr(30px);
            letter-spacing: 2.32px;
            width: 235px;
            border-radius: 26px;
            padding: 7px 0;

            @media screen and (max-width: $lps) {
                @include monr(21px);
                max-width: 95%;
            }
        }

        ul {
            @include ul();
            text-align: left;

            li {
                width: 95%;
            }
        }
    }

    [class^="bg-"] {
        @media screen and (max-width: $lps) {
            width: 100% !important;
        }
    }

    .block-title {
        text-align: center;
        @include monb(43px);
        color: $grey2;
        padding-top: 20px;

        @media screen and (max-width: $lps) {
            @include monb(30px);
        }
    }

    .col-od-md-2 {
        width: calc((100% - 80px) / 5);
    }

    .col-od-md-3 {
        width: calc((100% - 60px) / 4);
    }

    .col-od-md-4 {
        width: calc((100% - 40px) / 3);
    }

    .col-od-md-6 {
        width: calc((100% - 20px) / 2);
    }

    .menu-horizontal.menu {
        background: $green;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);

        @media screen and (min-width: $lps) {
        }
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 200;

        .menu-list {
            @include vertical-menu-wrapper();
            padding: 22px 0 0;

            @media screen and (max-width: $lps) {
                display: none;
            }

            .menu-item {
                @include vertical-menu-item();

                @media screen and (max-width: $lps) {
                    margin-right: 10px;
                    margin-bottom: 10px;
                    justify-content: flex-start;
                }
            }
        }
    }

    .section-1 {
        margin-top: 80px;
        @media screen and (min-width: $lps) {
            margin-top: 140px;
        }

        .container {
            min-height: 100vh;

            @media screen and (max-width: $lps),
            screen and (max-height: 650px) {
                min-height: auto;
            }
        }

        .title {
            margin-top: 71px;
            color: $grey4;
            @include moneb(62px);
            max-width: 995px;

            span {
                color: $green;
            }

            @media screen and (max-height: 768px) {
                @include moneb(50px);
            }

            @media screen and (max-width: $lps) {
                @include moneb(30px);
                margin-top: 15px;
            }
        }

        .partners {
            position: absolute;
            left: 0px;
            bottom: 100px;

            a {
                display: block;
                margin-bottom: 30px;
            }

            @media screen and (max-height: 768px) {
                bottom: 10px;
            }

            @media screen and (max-height: 650px) {
                position: relative;
                bottom: auto;
                align-items: center;
                display: flex;
                justify-content: center;
                text-align: center;
                margin-top: 20px;

                a {
                    display: inline;

                    img {
                        max-width: 80%;
                    }
                }
            }

            @media screen and (max-width: $lps) {
                position: relative;
                bottom: auto;
                align-items: center;
                display: flex;
                justify-content: center;
                text-align: center;
                margin-top: 20px;

                a {
                    display: inline;

                    img {
                        max-width: 80%;
                    }
                }
            }
        }

        .bg {
            position: absolute;
            bottom: 40px;
            right: 0;
            z-index: -1;
            @include bgimg('../images/bg-1.png', top, contain);
            max-width: calc(((100% - 1170px) / 2) + 1170px - 150px);
            height: calc(100vh - 300px);

            @media screen and (max-width: $lps),
            screen and (max-height: 650px) {
                position: relative;
                bottom: auto;
                max-width: 100%;
                height: calc(100vh - 150px);
            }

            @media screen and (max-width: $lps) {
                height: 200px;
            }

        }
    }

    .section-2 {
        margin: 110px 0;

        @media screen and (max-width: $lps) {
            margin: 50px 0 0;
        }

        .desc {
            @include monr($montserrat-b-23);
            color: $grey4;

            span {
                background: $orange;
                color: $white;
                @include monb($montserrat-b-23);
                padding: 3px;
            }

            @media screen and (max-width: $lps) {
                @include monr(18px);
            }
        }
    }

    .section-3 {
        width: 100%;

        @media screen and (max-width: $lps) {
            // height: auto;
            // padding: 30px 0 0;
        }

        .video-wrapper {
            position: relative;
            height: calc(100vh - 196px);

            @media screen and (max-width: 480px) {
                height: 250px;
            }
        }

        #big-play-button {
            background: transparent;
            border: none;
            background-image: url('../images/play.png');
            width: 232px;
            height: 232px;
            opacity: 0.72;
            position: absolute;
            top: calc(50% - 116px);
            left: calc(50% - 116px);
            background-size: contain;

            @media screen and (max-width: $lps) {
                width: 50px;
                height: 50px;
                top: calc(50% - 25px);
                left: calc(50% - 25px);
            }

        }

        #pause-button {
            background: transparent;
            border: none;
            background-image: url('../images/video-pause-button.png');
            width: 70px;
            height: 70px;
            opacity: 0.72;
            position: absolute;
            bottom: 35px;
            left: 35px;
            background-size: contain;
            display: none;
            top: calc(50% - 35px);
            left: calc(50% - 35px);

            @media screen and (max-width: $lps) {
                bottom: 5px;
                left: 5px;
                width: 30px;
                height: 30px;
                display: none !important;
            }
        }

        .video_fallback {
            position: absolute;
            right: 0;
            top: 0;
            -webkit-transition: all .5s ease-in-out;
            -moz-transition: all .5s ease-in-out;
            -o-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
            @include bgimg('../images/video-bg.png', center, contain);
            height: calc(100%);
        }

        #bgvid {
            opacity: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: calc(100%);
            z-index: -100;
            -webkit-transition: all .5s ease-in-out;
            -moz-transition: all .5s ease-in-out;
            -o-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
            display: none;

            @media screen and (max-width: $lps) {
                // height: auto;
                width: 100%;
            }
        }

        video#bgvid.show {
            opacity: 1;
        }
    }

    .section-4 {
        // min-height: 700px;

        .bg-1 {
            @include bgimg('../images/bg-4.png', top, contain);
            position: absolute;
            left: 0;
            top: 0;
            height: 600px;
            width: $section-width-withou-one-part;
        }

        .bg-2 {
            @include bgimg('../images/bg-5-1.png', bottom, cover);
            z-index: -1;
            position: absolute;
            right: 0;
            bottom: 0;
            height: 710px;
            width: 100%;

            @media screen and (max-width: $lps) {
                height: 390px;
            }
        }

        .title {
            @include monb(43px);
            color: $grey2;
            padding: 58px 0 102px;
            text-align: center;

            @media screen and (max-width: $lps) {
                @include monb(30px);
                padding-top: 70px;
                padding-bottom: 50px;
            }
        }

        .flex-od-row {
            text-align: center;
            align-items: flex-start;
            padding: 0 45px 250px;

            @media screen and (max-width: $lps) {
                padding: 0 10px;

                .col-od-md-4:last-child {
                    padding-bottom: 150px;
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    .section-5 {
        background: $orange;

        .title {
            @include monb(43px);
            color: $white;
            text-align: center;
            margin-bottom: 55px;

            @media screen and (max-width: $lps) {
                @include monb(30px);
                margin-bottom: 30px;
            }
        }

        .flex-od-row {
            text-align: center;
            align-items: flex-start;
            flex-wrap: wrap;
            flex-direction: row;
            display: -webkit-box;
            flex: 1;
            padding: 0 45px 50px;

            .col-od-md-4 {
                @include monl($montserrat-l-20);
                color: $grey3;
                background: $white;
                padding: 50px 25px;
                text-align: left;
            }

            @media screen and (max-width: $lps) {
                padding: 0 10px;
                display: block !important;
            }
        }
    }

    .section-6 {
        min-height: 100px;

        @media screen and (min-width: $lps) {
            min-height: 300px;
        }

        .bg-1 {
            @include bgimg('../images/bg-5-2.png', bottom, cover);
            position: absolute;
            left: 0;
            bottom: 0;
            height: 313px;
            width: 100%;
            z-index: -1;

            @media screen and (max-width: $lps) {
                height: 190px;
            }
        }

        @media screen and (max-width: $lps) {}

        .flex-od-row {
            justify-content: center;

            .center {
                background: $white;
                border-radius: 50px;
                padding: 10px 20px;
                align-items: center;
                display: flex;
                justify-content: center;

                a {
                    margin-right: 65px;

                    &:last-child {
                        margin-right: 0;
                    }

                    img {
                        width: 150px;
                    }

                    @media screen and (max-width: $lps) {
                        margin-right: 15px;
                    }
                }

                @media screen and (max-width: $lps) {
                    margin: 0 20px;
                }
            }
        }
    }

    .section-7 {
        background-color: $light-green;

        .bg-1 {
            @include bgimg('../images/bg-7-1.png', bottom, cover);
            position: absolute;
            height: 264px;
            width: 100%;
            z-index: -1;
        }

        .flex-od-row {
            text-align: center;
            align-items: flex-start;
            flex-wrap: wrap;
            flex-direction: row;
            display: -webkit-box;
            flex: 1;
            padding: 90px 45px 0px;

            @media screen and (max-width: $lps) {
                padding: 20px 10px 0px;
                display: block !important;
            }

            .col-od-md-4 {
                position: relative;
                @include monr($montserrat-22);
                color: $dark;
                background: transparent;
                padding: 0px 25px;
                text-align: left;
                line-height: 32.4px;

                @media screen and (max-width: $lps) {
                    @include monr(18px);

                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }

                p {
                    margin-top: 0;
                    margin-bottom: 0px;
                }

                span {
                    display: inline;
                    @include monb($montserrat-22);
                }

                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 100%;
                    background-color: $green;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    .section-8 {
        margin-top: 200px;
        padding-bottom: 200px;

        .bg-1 {
            @include bgimg('../images/bg-8-1.png', bottom, cover);
            position: absolute;
            bottom: 0;
            height: 383px;
            width: 100%;
            z-index: -1;
        }

        .bg-2 {
            // background: $orange;
            // position: absolute;
            // bottom: 0;
            // height: 105px;
            // width: 100%;
            // z-index: 2;
        }

        .flex-od-row {
            text-align: center;
            align-items: flex-start;
            padding: 72px 45px 50px;

            &:last-child {
                padding-top: 0;
                padding-bottom: 0;
            }

            @media screen and (max-width: $lps) {
                padding: 20px 10px 0px;
            }

            .col-od-md-6 {
                align-items: flex-start;
                margin-right: 150px;
                color: $grey3;
                @include monl($montserrat-l-20);

                @media screen and (max-width: $lps) {
                    @include monl(18px);
                }

                &:last-child {
                    margin-right: 0;
                }

                .label {
                    background: $green;
                    margin-bottom: 36px;
                    text-transform: uppercase;
                    width: 329px;
                }

                ul {
                    margin-bottom: 0;

                    li {
                        align-items: flex-start;
                    }
                }
            }
        }
    }

    .section-9 {
        background: $orange;
        padding-bottom: 100px;

        .block-title {
            color: $white;
        }

        .flex-od-row {
            text-align: center;
            align-items: flex-start;
            padding: 100px 0px 120px;

            @media screen and (max-width: $lps) {
                padding: 50px 10px 0px;
            }

            justify-content: center;

            &:last-child {
                padding-top: 0;
                padding-bottom: 40px;
            }

            .col-od-md-3 {
                align-items: center;
                margin-right: 40px;
                color: $grey3;

                .desc {
                    text-align: center;
                    line-height: 32.4px;
                    color: $white;
                    @include monr($montserrat-22);
                    margin-top: 20px;

                    @media screen and (max-width: $lps) {
                        @include monr(18px);
                    }
                }
            }
        }
    }

    .section-10 {
        background: $light-green;

        .container {
            z-index: 2;
        }

        .bg-1 {
            @include bgimg('../images/bg-9-1.png', bottom, cover);
            position: absolute;
            height: 210px;
            width: 100%;
            z-index: 1;
        }

        .bg-2 {
            @include bgimg('../images/bg-9-2.png', bottom, cover);
            position: absolute;
            height: 235px;
            width: 100%;
            z-index: 1;
        }

        .block-title {
            color: $grey2;
            padding-top: 150px;
        }

        .flex-od-row {
            text-align: center;
            align-items: flex-start;
            padding: 100px 0px 120px;
            justify-content: center;

            @media screen and (max-width: $lps) {
                padding: 50px 10px 0px;
            }

            &:last-child {
                padding-top: 0;
                padding-bottom: 0px;
                padding: 0 10px;
            }

            .col-od-md-3,
            .col-od-md-2 {
                align-items: center;

                .desc {
                    text-align: center;
                    line-height: 32.4px;
                    color: $grey2;
                    @include monr($montserrat-22);
                    margin-top: 20px;

                    @media screen and (max-width: $lps) {
                        @include monr(18px);
                    }
                }

                @media screen and (max-width: $lps) {
                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }

    .section-11 {
        margin-top: 250px;

        .block-title {
            padding-top: 150px;
        }

        .flex-od-row {
            margin: 150px 0;
            text-align: left;
            line-height: 32.4px;
            position: relative;
            @include monr($montserrat-22);

            @media screen and (max-width: $lps) {
                @include monr(18px);
            }

            @media screen and (max-width: $lps) {
                margin: 50px 0;
            }

            &:nth-child(1) {}

            &:nth-child(2) {
                margin: 250px 0;

                @media screen and (max-width: $lps) {
                    margin: 50px 0;
                }
            }

            &:nth-child(3) {
                margin: 400px 0;

                @media screen and (max-width: $lps) {
                    margin: 50px 0;
                }
            }

            .container {
                display: flex;
                text-align: left;

                @media screen and (max-width: $lps) {
                    display: block;
                }
            }

            .title {
                text-align: left;
                @include monb($montserrat-22);

                @media screen and (max-width: $lps) {
                    @include monb(18px);
                }
            }

            .col-od-md-6 {
                align-items: flex-start;
                display: block;
            }

            .bg-img-1 {
                @include bgimg('../images/img-11-1.png', center, cover);
                position: absolute;
                height: 695px;
                width: 950px;
                z-index: 1;
                right: calc(50% - 950px);
                top: calc(50% - 345px);
            }

            .bg-img-2 {
                @include bgimg('../images/img-11-2.png', center, cover);
                position: absolute;
                height: 625px;
                width: 908px;
                z-index: 1;
                left: calc(50% - 950px - 60px);
                top: calc(50% - 295px);
            }

            .bg-img-3 {
                @include bgimg('../images/img-11-3.png', center, cover);
                position: absolute;
                height: 901px;
                width: 886px;
                z-index: 1;
                right: calc(50% - 950px);
                top: calc(50% - 455px);
            }

            @media screen and (max-width: $lps) {
                [class^="bg-img-"] {
                    position: relative;
                    top: auto;
                    right: auto;
                    left: auto;
                    bottom: auto;
                    height: 350px;
                    display: none;
                }
            }
        }
    }

    .section-12 {
        min-height: 865px;
        margin-top: 220px;

        @media screen and (max-width: $lps) {
            min-height: auto;
            margin-top: 70px;
            color: $white;
        }

        .bg-1 {
            @include bgimg('../images/bg-10.png', bottom, cover);
            position: absolute;
            height: 865px;
            width: 100%;
            z-index: 0;

            @media screen and (max-width: $lps) {
                display: none;
            }
        }

        .container {
            padding-top: 100px;

            @media screen and (max-width: $lps) {
                background: $green;
                width: 100%;
                padding-bottom: 20px;
                padding: 0 10px;
            }
        }

        .block-title {
            @media screen and (max-width: $lps) {
                display: block;
                color: $white;
            }
        }

        .flex-od-row {
            color: $dark;
            @include monr($montserrat-22);
            margin-top: 80px;
            text-align: left;
            align-items: baseline;
            

            @media screen and (max-width: $lps) {
                @include monr(18px);
                color: $white;
            }

            .col-od-md-6 {
                text-align: left;
                @media screen and (max-width: $lps) {
                    text-align: center;
                }
            }

            a {
                color: $dark;
                margin-top: 5px;
                @media screen and (max-width: $lps) {
                    display: block;
                    color: $white;
                }
            }
        }

        .block-title.second {
            margin-top: 50px;
            color: $white;
        }

        .desc {
            line-height: 32.4px;
            text-align: center;
            @include monr($montserrat-22);

            @media screen and (max-width: $lps) {
                @include monr(18px);
                padding-bottom: 20px;
            }

            margin-top: 40px;
            color: $white;

            a {
                color: $white;
            }
        }
    }

    .section-13 {
        padding-top: 50px;

        .container {
            padding-right: 40px;
            @include monr(21px);

            @media screen and (max-width: $lps) {
                @include monr(18px);
            }

            color: $dark;
            line-height: 27.32px;

            @media screen and (max-width: $lps) {
                padding-right: 0px;
            }
        }

        .partners {
            align-items: center;
            @include flexbox();
            justify-content: center;
            margin: 30px 0;

            a {
                margin-right: 40px;
                text-align: center;

                &:last-child {
                    margin-right: 0;
                }

                img {
                    // width: 70%;
                }
            }
        }
    }

    .section-14 {
        background: $orange;
        height: 41px;
    }
}